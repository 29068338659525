import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "src/app/core/guard/auth.guard";

import { GuestGuard } from "./core/guard/guest.guard";
import { UnitDetailsResolver } from "./pages/project-details/resolver/unitDetails-resolver.service";
import { UserGuard } from "./core/guard/user.guard";
import { ResellDetailsResolver } from "./pages/resell-page/resolver/resell-details.resolver";

export const routes: Routes = [
  { path: "", loadChildren: () => import("./pages/home/home.module").then((m) => m.HomeModule) },
  { path: "listings", loadChildren: () => import("./pages/listings/listings.module").then((m) => m.ListingsModule) },
  {
    path: "details/:unitid",
    resolve: { unit: UnitDetailsResolver },
    loadChildren: () => import("./pages/project-details/project-details.module").then((m) => m.ProjectDetailsModule),
  },
  {
    path: "buy/compounds/:country/:reg/:compoundName/:unitid/:unitDetails",
    loadChildren: () => import("./pages/project-details/project-details.module").then((m) => m.ProjectDetailsModule),
  },
  {
    path: "developer/:developerid",
    loadChildren: () => import("./pages/developer/developer.module").then((m) => m.DeveloperModule),
  },
  {
    path: "buy/developer/:developerid/:developerName",
    loadChildren: () => import("./pages/developer/developer.module").then((m) => m.DeveloperModule),
  },
  {
    path: "profile",
    loadChildren: () => import("./pages/profile/profile.module").then((m) => m.ProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: "transaction-details/:id",
    loadChildren: () => import("./pages/transaction-details/transaction-details.module").then((m) => m.TransactionDetailsModule),
  },
  {
    path: "receipt/:receiptID",
    loadChildren: () => import("./pages/receipt/receipt.module").then((m) => m.ReceiptModule),
    canActivate: [AuthGuard],
  },
  {
    path: "emptyreceipt/:receiptID",
    loadComponent: () => import("./pages/project-details/components/empty-page/empty-page.component").then((m) => m.EmptyPageComponent),
    data: { skipRouteLocalization: true },
  },
  { path: "about-us", loadChildren: () => import("./pages/about-us/about-us.module").then((m) => m.AboutUsModule) },
  {
    path: "contact-us",
    loadChildren: () => import("./pages/contact-us/contact-us.module").then((m) => m.ContactUsModule),
  },
  {
    path: "offers",
    loadChildren: () => import("./pages/offers/offers.module").then((m) => m.OffersModule),
  },
  {
    path: "compare-units",
    loadChildren: () => import("src/app/pages/compare-units/compare-units.module").then((m) => m.CompareCompoundsModule),
  },
  {
    path: "compare-compounds",
    loadChildren: () => import("src/app/pages/compare-compounds/compare-compounds.module").then((m) => m.CompareCompoundsModule),
  },
  {
    path: "compound/:compoundId",
    loadChildren: () => import("./pages/compound/compound.module").then((m) => m.CompoundModule),
  },
  {
    path: "buy/compound/:country/:reg/:compoundId/:compoundName",
    loadChildren: () => import("./pages/compound/compound.module").then((m) => m.CompoundModule),
  },
  { path: "locations", loadChildren: () => import("./pages/locations/locations.module").then((m) => m.LocationsModule) },

  {
    path: "reservation/:offerId",
    loadChildren: () => import("./pages/reservation/reservation.module").then((m) => m.ReservationModule),
    canActivate: [AuthGuard],
  },
  {
    path: "location/:locationId",
    loadChildren: () => import("./pages/location/location.module").then((m) => m.LocationModule),
  },
  {
    path: "buy/location/:locationId/:locationName",
    loadChildren: () => import("./pages/location/location.module").then((m) => m.LocationModule),
  },
  { path: "pre-launch/:preLaunchId", loadChildren: () => import("./pages/pre-launch/pre-launch.module").then((m) => m.PreLaunchModule) },
  { path: "projects", loadChildren: () => import("./pages/projects/projects.module").then((m) => m.ProjectsModule) },
  { path: "developers", loadChildren: () => import("./pages/developers/developers.module").then((m) => m.DevelopersModule) },
  { path: "salesman", loadChildren: () => import("./sales-man/sales-man.module").then((m) => m.SalesManModule) },
  {
    path: "meeting/:meetingNumber/:pwd",
    loadChildren: () => import("./sales-man/zoom-meeting/zoom-meeting.module").then((m) => m.ZoomMeetingModule),
  },
  {
    path: "term",
    loadChildren: () => import("./pages/terms/terms.module").then((m) => m.TermsModule),
  },
  {
    path: "policy",
    loadChildren: () => import("./pages/policy/policy.module").then((m) => m.PolicyModule),
  },
  {
    path: "faqs",
    loadChildren: () => import("./pages/faq/faqs.module").then((m) => m.FaqsModule),
  },
  {
    path: "forget-password",
    loadComponent: () => import("./features/reset-password/reset-password.component").then((m) => m.ResetPasswordComponent),
    canActivate: [GuestGuard],
  },
  {
    path: "physical-meeting",
    loadComponent: () =>
      import("./shared/components/physical-meeting-url/physical-meeting-url.component").then((m) => m.PhysicalMeetingUrlComponent),
  },
  {
    path: "schedule-meeting",
    loadComponent: () =>
      import("./shared/components/physical-meeting-url/physical-meeting-url.component").then((m) => m.PhysicalMeetingUrlComponent),
  },
  {
    path: "salesman-profile",
    loadChildren: () => import("./sales-man/salesman-profile/salesman-profile.module").then((m) => m.SalesmanProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: "customer-service",
    loadChildren: () => import("./customer-service/customer-service.module").then((m) => m.CustomerServiceModule),
    canActivate: [AuthGuard],
  },
  {
    path: "customer-service/customers/:tab",
    loadChildren: () => import("./customer-service/modules/customers/customers.module").then((m) => m.CustomersModule),
    canActivate: [AuthGuard],
  },
  {
    path: "customer-service/create-customer",
    loadChildren: () =>
      import("./customer-service/modules/customer-form-info/customer-form-info.module").then((m) => m.CustomerFormInfoModule),
    canActivate: [AuthGuard],
  },
  {
    path: "customer-service/edit-customer/:id",
    loadChildren: () =>
      import("./customer-service/modules/customer-form-info/customer-form-info.module").then((m) => m.CustomerFormInfoModule),
    canActivate: [AuthGuard],
  },
  {
    path: "customer-service/details/:id",
    loadChildren: () => import("./customer-service/modules/customer-details/customer-details.module").then((m) => m.CustomerDetailsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "customer-service/lead-details/:id/:custId",
    loadChildren: () => import("./customer-service/modules/lead-details/lead-details.module").then((m) => m.LeadDetailsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "customer-service/opportunity-details/:id/:custId",
    loadChildren: () =>
      import("./customer-service/modules/opportunity-details/opportunity-details.module").then((m) => m.OpportunityDetailsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "customer-service/unit-details/:id",
    loadChildren: () => import("./customer-service/modules/unit-details/unit-details.module").then((m) => m.UnitDetailsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "customer-service/opportunity-form/:id/:custId",
    loadChildren: () =>
      import("./customer-service/modules/opportuntity-form/opportuntity-form.module").then((m) => m.OpportuntityFormModule),
    canActivate: [AuthGuard],
  },
  {
    path: "customer-service/convert-opportunity/:id/:custId",
    loadChildren: () => import("./customer-service/modules/convert-page/convert-page.module").then((m) => m.ConvertPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "customer-service/ticket-details/:id/:custId",
    loadChildren: () => import("./customer-service/modules/ticket-details/ticket-details.module").then((m) => m.TicketDetailsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "customer-service/ticket-form/:id/:custId",
    loadChildren: () => import("./customer-service/modules/ticket-form/ticket-form.module").then((m) => m.TicketFormModule),
    canActivate: [AuthGuard],
  },
  {
    path: "customer-service/add-ticket/:custId",
    loadChildren: () => import("./customer-service/modules/ticket-form/ticket-form.module").then((m) => m.TicketFormModule),
    canActivate: [AuthGuard],
  },
  {
    path: "customer-service/add-lead/:custId",
    loadChildren: () => import("./customer-service/modules/add-lead/add-lead.module").then((m) => m.EditLeadModule),
    canActivate: [AuthGuard],
  },
  {
    path: "addYourUnit/:unitId",
    loadComponent: () => import("./pages/resell/add-property.component").then((c) => c.AddPropertyComponent),
    canActivate: [AuthGuard, UserGuard],
  },
  // {
  //   path: "buy/resell",
  //   loadChildren: () => import("./pages/resell-page/resell-page.module").then((m) => m.ResellPageModule),
  // },
  // {
  //   path: "resell-details/:unitId",
  //   loadComponent: () =>
  //     import("./pages/resell-page/components/resell-page-details/resell-page-details.component").then((c) => c.ResellPageDetailsComponent),
  //   resolve: { unit: ResellDetailsResolver },
  // },
  {
    path: "404",
    loadChildren: () => import("./pages/page-not-found/page-not-found.module").then((m) => m.PageNotFoundModule),
  },
  { path: "adib", loadChildren: () => import("./pages/adib/adib.module").then((m) => m.AdibModule) },
  { path: "adib/apply", loadChildren: () => import("./pages/adib/adib.module").then((m) => m.AdibModule) },
  // {
  //   path: "**",
  //   loadChildren: () => import("./pages/page-not-found/page-not-found.module").then((m) => m.PageNotFoundModule),
  // },
  {
    path: "**",
    loadChildren: () => import("./pages/search-seo/search-seo.module").then((m) => m.SearchSeoModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "disabled",
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
