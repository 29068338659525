import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../service/utilities/local-storage/storage.service';
import { Location } from '@angular/common';
import { UserEnum } from '../enum/user.enum';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {

  constructor(private location: Location, private storageService: StorageService) { }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = JSON.parse(this.storageService.getItem("User_Basic_Info"))
    return new Promise<boolean>((resolve, reject) => {
      if (user.userType == UserEnum.USER) {
        resolve(true)
      } else {
        reject(false)
        this.location.back()
      }
    })
  }

}
