<div class="header_wrapper">
  <header id="navigationContainer">
    <nav class="nav__container">
      <!-- NAVIGATION SECTION -->
      <div class="nav__element">
        <ng-container [ngTemplateOutlet]="logoEshtri"></ng-container>
      </div>
      <ul class="nav__elements">
        <li class="nav__element">
          <a class="nav__item" routerLink="{{ '/buy/properties' | localize }}" routerLinkActive="active">{{
            "navbar.search" | translate
          }}</a>
        </li>

        <!-- <li class="nav__element resel">
          <mat-icon svgIcon="resell"></mat-icon>
          <a class="nav__item" routerLink="{{ '/buy/resell' | localize }}" routerLinkActive="active">{{
            "navbar.resell" | translate
            }}</a>
        </li> -->

        <li class="action__buttons nav__element" *rxLet="discoverList$; let discoverList; parent: false">
          <button [matMenuTriggerFor]="list" class="nav__item action__btn">
            <span class="user__name">{{ "navbar.discover" | translate }}</span>
            <mat-icon>expand_more</mat-icon>
            <mat-menu xPosition="before" #list="matMenu" class="listMenu">
              <ng-container
                [ngTemplateOutlet]="discoveMenuItems"
                [ngTemplateOutletContext]="{ list: discoverList['compounds'], link: 'compound', value: 'projects' }"
              ></ng-container>
              <hr />
              <ng-container
                [ngTemplateOutlet]="discoveMenuItems"
                [ngTemplateOutletContext]="{ list: discoverList['developers'], link: 'developer', value: 'developers' }"
              ></ng-container>
              <hr />
              <ng-container
                [ngTemplateOutlet]="discoveMenuItems"
                [ngTemplateOutletContext]="{ list: discoverList['locations'], link: 'location', value: 'locations' }"
              ></ng-container>
            </mat-menu>
          </button>
        </li>
        <ng-container *rxLet="OffersCheck; let offers; patchZone: false">
          <li *ngIf="offers.LandingProjects?.length > 0" class="nav__element">
            <a class="nav__item" routerLink="{{ '/offers' | localize }}" routerLinkActive="active">{{ "navbar.offers" | translate }}</a>
          </li>
        </ng-container>

        <li class="nav__element request">
          <mat-icon svgIcon="chat"></mat-icon>
          <button class="nav__item" (click)="openRequestPopup()">{{ "navbar.request" | translate }}</button>
        </li>
        <li *ngIf="userBasic?.userType == UserTypes.EMPLOYEE" class="nav__element">
          <a class="nav__item" routerLink="{{ '/customer-service/customers/1' | localize }}" routerLinkActive="active">{{
            "cs.customers" | translate
          }}</a>
        </li>

        <li class="nav__element adib">
          <mat-icon svgIcon="calculator"></mat-icon>
          <a class="nav__item" routerLink="{{ '/adib' | localize }}" routerLinkActive="active">{{ "navbar.adib" | translate }}</a>
        </li>

        <li class="nav__element" *ngIf="userBasic?.userType == UserTypes.USER">
          <a class="nav__item" routerLink="{{ '/addYourUnit/0' | localize }}" routerLinkActive="active">{{
            "navbar.addProperty" | translate
          }}</a>
        </li>

        <li class="nav__element">
          <app-countries-dropdown></app-countries-dropdown>
        </li>
        <li class="nav__element">
          <button class="language action__btn" (click)="changeLang('ar')" *ngIf="language == 'en'">عربي</button>
          <button class="language action__btn" (click)="changeLang('en')" *ngIf="language == 'ar'">English</button>
        </li>
        <li class="nav__element">
          <ng-container [ngTemplateOutlet]="hotLineBtn"></ng-container>
        </li>
      </ul>
      <!-- END OF NAVIGATION SECTION -->
    </nav>

    <!-- HAMBURGER MENU FOR WHEN IN MOBILE VIEW. TRIGGERS SIDENAV -->
    <div class="mobile__toggle">
      <div class="flex">
        <button class="toggle__btn" color="primary" mat-mini-fab (click)="menuToggle.emit()" aria-label="Justify">
          <mat-icon>menu</mat-icon>
        </button>

        <button class="language action__btn" (click)="changeLang(language == 'ar' ? 'en' : 'ar')" fxLayout="row">
          <mat-icon>language</mat-icon>
          <span>{{ language == "ar" ? "en" : "ar" }}</span>
        </button>
        <ng-container [ngTemplateOutlet]="hotLineBtn"></ng-container>
      </div>
      <div>
        <ng-container [ngTemplateOutlet]="logoEshtri"></ng-container>
      </div>
    </div>
  </header>
</div>
<ng-template #discoveMenuItems let-list="list" let-link="link" let-value="value">
  <ul class="discover" *ngIf="list">
    <li>
      <label>{{ "navbar." + value | translate }}</label>
    </li>
    <li *ngFor="let item of list; trackBy: trackedbyService.identifier">
      <a
        [routerLink]="'/' + link + '/' + item.id + '-' + item.title.split(' ').join('-') | localize"
        class="nav__item"
        routerLinkActive="active"
      >
        {{ item.title }}
      </a>
    </li>
    <li class="primary">
      <a [routerLink]="'/' + value | localize" class="nav__item" routerLinkActive="active">{{ "navbar.all_" + value | translate }}</a>
      <mat-icon>{{ language === "en" ? "navigate_next" : "navigate_before" }}</mat-icon>
    </li>
  </ul>
</ng-template>

<ng-template #hotLineBtn>
  <button id="hotLine" aria-label="Justify">
    <a href="tel:16407" [ngStyle]="{ 'flex-direction': language == 'ar' ? 'row-reverse' : 'row' }">
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <g id="Layer_3" data-name="Layer 3" transform="translate(-1 -1)">
          <path
            id="Path_1945"
            data-name="Path 1945"
            d="M18.421,13.956c-.032-.026-3.625-2.6-4.6-2.429-.469.083-.737.4-1.274,1.042-.086.1-.295.35-.455.526a7.476,7.476,0,0,1-.991-.4A8.22,8.22,0,0,1,7.308,8.9a7.475,7.475,0,0,1-.4-.991c.176-.161.424-.37.529-.458.637-.534.956-.8,1.039-1.271.17-.971-2.4-4.568-2.429-4.6A1.373,1.373,0,0,0,5.02,1C3.977,1,1,4.862,1,5.513c0,.038.055,3.88,4.793,8.7C10.607,18.945,14.45,19,14.487,19,15.138,19,19,16.023,19,14.98a1.372,1.372,0,0,0-.579-1.024Zm-4,3.84c-.524-.043-3.749-.469-7.78-4.429C2.66,9.314,2.246,6.081,2.2,5.58A16.233,16.233,0,0,1,5.028,2.243c.024.024.056.06.1.107A21.235,21.235,0,0,1,7.269,5.988a7.132,7.132,0,0,1-.61.547,6.02,6.02,0,0,0-.907.853l-.146.2.043.247a6.851,6.851,0,0,0,.579,1.585,9.426,9.426,0,0,0,4.349,4.348,6.833,6.833,0,0,0,1.585.58l.247.043.2-.146a6.07,6.07,0,0,0,.857-.911c.188-.224.439-.524.534-.608a21.1,21.1,0,0,1,3.647,2.147c.05.042.085.074.108.1A16.218,16.218,0,0,1,14.421,17.8Z"
            transform="translate(0 0)"
          />
        </g>
      </svg>
      <div>{{ "aboutUs.16407" | translate }}</div>
    </a>
  </button>
</ng-template>

<ng-template #logoEshtri>
  <a [routerLink]="(userBasic?.userType != UserTypes.EMPLOYEE ? '/' : '/listings') | localize" class="logo">
    <img
      alt="eshtri aqar"
      class="image__logo"
      src="assets/logo/eshtri.{{ language == 'en' ? 'aqar-logo' : 'aqar-13' }}.svg"
      width="100"
      height="100"
    />
  </a>
</ng-template>
