import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Params } from "@angular/router";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})

/*
Responsible for mutating output from REST APIs to allow for:
 - Handling of custom responses from the server
 - Adding route params from the environment
 - Adding HTTP Headers that apply to requests
*/
export class ApiService {
  constructor(private http: HttpClient) { }

  // Modifies GET request to take path from environment and header from global variable
  getRequest<T>(path: string): Observable<T> {
    return this.http.get<T>(`${environment.backendUrl}/${path}`).pipe(
      map((res: any) => {
        return this.handleServerResponse(res);
      })
    );
  }
  getIpRequest<T>(): Observable<T> {
    return this.http.get<T>(`${environment.ipAddress}`).pipe(
      map((res: any) => {
        return this.handleServerResponse(res);
      })
    );
  }

  getRequestParams<T>(path: string, params: any): Observable<T> {
    let cleanedParams = this.cleanParams(params);
    return this.http.get<T>(`${environment.backendUrl}/${path}`, { params: cleanedParams }).pipe(
      map((res: any) => {
        return this.handleServerResponse(res);
      })
    );
  }
  patchRequest<T>(path: string, data: any): Observable<T> {
    return this.http.patch<T>(`${environment.backendUrl}/${path}`, data).pipe(
      map((res: any) => {
        return this.handleServerResponse(res);
      })
    );
  }
  editRequest<T>(path: string, data: any): Observable<T> {
    return this.http.put<T>(`${environment.backendUrl}/${path}`, data).pipe(
      map((res: any) => {
        return this.handleServerResponse(res)
      })
    )
  }

  //method to clean params object from null or undefined parameters
  cleanParams(params) {
    if (params) {
      Object.keys(params).forEach((key) => {
        if (params[key] === null || params[key] === undefined) {
          delete params[key];
        }
      });
    }
    return params;
  }

  // Modifies POST request to take path from environment and header from global variable
  postRequest<T>(path: string, data: any): Observable<T> {
    return this.http.post<T>(`${environment.backendUrl}/${path}`, data).pipe(
      map((res: any) => {
        return this.handleServerResponse(res);
      })
    );
  }
  postRequestAttachment<T>(path: string, data: any): Observable<T> {
    return this.http
      .post<T>(`${environment.backendUrl}/${path}`, data, {
        reportProgress: true,
        observe: "events",
        headers: new HttpHeaders({
          "Content-Type": "multipart/formdata",
        }),
      })
      .pipe(
        map((res: any) => {
          return this.handleServerResponse(res);
        })
      );
  }

  deleteRequest<T>(path: string): Observable<T> {
    return this.http.delete<T>(`${environment.backendUrl}/${path}`).pipe(
      map((res: any) => {
        return this.handleServerResponse(res);
      })
    );
  }

  /*
  Takes server response and checks:
  - If server responds with a failure [type == "F"], it maps the data to an error template which throws the error to the calling function
  - If server responds with a sucess [type == "S"], return 'data' attribute
  */
  handleServerResponse(res: any) {
    if (res?.type === "F") {
      throw new HttpErrorResponse({
        //Responsible for mapping server errors to the normal HTTP ERROR response
        error: res["msg1"], //'msg1' houses error message from server
        statusText: "F", // adds 'F' as fail text
        status: 4000, // custom error status code to differeniate between server errors and normal browser request errors
      });
    }
    return res; // if success, returns the 'data' attribute in the response
  }

  // [TECHNICAL] Responsible for retreiving PDF file from the server
  postRequestPDF<T>(path: string, trans_id: string): Observable<any> {
    return new Observable((Obs) => {
      var oReq = new XMLHttpRequest();
      oReq.open("POST", `${environment.backendUrl}/${path}`, true);
      oReq.setRequestHeader("content-type", "application/json");
      oReq.responseType = "arraybuffer";
      oReq.withCredentials = true;
      oReq.onload = () => {
        var arrayBuffer = oReq.response;
        var byteArray = new Uint8Array(arrayBuffer);
        const blob = new Blob([byteArray], {
          type: "application/pdf",
        });
        Obs.next(blob);
      };
      oReq.send(trans_id);
    });
  }

  postRequestExcel<T>(path: string, params: Params) {
    const option = new HttpHeaders({
      "content-type": "application/vnd.ms-excel",
    });
    return this.http.get(`${environment.backendUrl}/${path}`, {
      headers: option,
      observe: "response",
      responseType: "blob",
      params,
    });
  }
}
