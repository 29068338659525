import { Injectable } from "@angular/core";
import { map, shareReplay } from "rxjs/operators";

import { HomeApiService } from "../api/home-api.service";
import { ApiService } from "src/app/core/api/api.service";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  lang = "en";
  promo$: any
  constructor(private api: ApiService, private homeApi: HomeApiService) {
    this.lang = localStorage.getItem("LOCALIZE_DEFAULT_LANGUAGE");
  }

  getHomePageLaunches(tab = 1): any {
    return this.homeApi.getLaunchByCategory(tab).pipe(
      map((res) => {
        res.forEach((launch) => {
          launch.key_features = launch?.compFeatures;
        });
        return res;
      })
    );
  }
  shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  getHomeOffers(catId): any {
    return this.homeApi.getOffers(catId)
  }
  getFeaturedCompounds(tab = 1, location): any {
    return this.homeApi.getCompoundByCategoryAndLocation(tab, location.regId).pipe(

      map((res: any) => {
        if (res?.length == 0) return null;
        return {
          title: location.text + " ",
          projects: res,
        };
      })
    );
  }
  getLocationData(tab = 1): any {
    return this.homeApi.getLocationsByCategory(tab)
  }

  getBlogs(): any {
    return this.api.getRequestParams<any>(`cms/home-page-blogs`, { '_locale': this.lang }).pipe(
      map((res) => res.Blogs));
  }

  getPromoHeader(): any {
    if (!this.promo$)
      this.promo$ = this.homeApi.getPromoHeader(this.lang).pipe(
        map((res: any) => {
          return {
            LandingProjects: this.shuffle(
              res?.promos?.map((project: any) => {
                return {
                  Title: project.promoTitle,
                  Description: project.promoText,
                  Url: `developer/${project.compId}`,
                  id: project.compId,
                  img: project.promoImg,
                };
              })
            ),
            Images: res?.developerLogos,
            residentialBanner: res?.residentialBanner,
            commercialBanner: res?.commercialBanner,
            hotelApartmentBanner: res?.hotelApartmentBanner,
            administrationBanner: res?.administrationBanner,
          };
        }), shareReplay()
      );
    return this.promo$
  }
  //#endregion
}
