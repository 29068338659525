import { ChangeDetectionStrategy } from "@angular/core";
// import { CallComponent } from "./../../../sales-man/shared-component/call/call.component";
import { AfterViewInit, Component, ComponentRef, createNgModule, Injector, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav, MatSidenavContent } from "@angular/material/sidenav";
import { NavigationEnd, Router } from "@angular/router";

import { HotToastService } from "@ngneat/hot-toast";
import { NgxHotjarService } from "ngx-hotjar";
import { interval, Observable, Subscription, timer } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { LazyLoadingPopupComponent, LazyLoadingPopupData } from "src/app/features/lazy-loading-popup/lazy-loading-popup.component";
import { OffersService } from "src/app/pages/offers/service/offers.service";
// import { ContactSalesmanDialogComponent } from "src/app/sales-man/contact-salesman-dialog/contact-salesman-dialog.component";
import { SalesManStatusModel } from "src/app/sales-man/salesman-status";
import { PullBackService } from "src/app/sales-man/service/pull-back.service";
import { SalesmanService } from "src/app/sales-man/service/salesman.service";
import { SocketService } from "src/app/sales-man/service/socket.service";
import { ZoomService } from "src/app/sales-man/service/zoom.service";
// import { PullBackPopupComponent } from "src/app/shared/components/pull-back-popup/pull-back-popup.component";
import { environment } from "src/environments/environment";
import { UserModel } from "../../model/user.model";
import { GeneralService } from "../../service/general.service";
import { ReservationTriggerService } from "../../service/reservation-trigger.service";
import { DataLayerService } from "../../tag-manager/services/data-layer/data-layer.service";
import { UserDAOService } from "../../api/user-dao.service";
import { UserEnum } from "../../enum/user.enum";

@Component({
  selector: "main-layout",
  templateUrl: "./main-layout.component.html",
  styleUrls: ["./main-layout.component.scss"],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainLayoutComponent implements OnInit, AfterViewInit {
  //#region  Input / Output
  // @ViewChild("chatbotContainer", { read: ViewContainerRef }) chatbotContainer: ViewContainerRef;
  // @ViewChild("zoomContainer", { read: ViewContainerRef }) zoomContainer: ViewContainerRef;
  @ViewChild("salesManContainer", { read: ViewContainerRef }) salesManContainer: ViewContainerRef;
  // @ViewChild("incomingCallContainer", { read: ViewContainerRef }) incomingCallContainer: ViewContainerRef;
  @ViewChild("sideBar") sideBar: MatSidenav;
  @ViewChild("content") content: MatSidenavContent;
  //#endregion
  //#region  declarations
  activeURL = this.router.url;
  userDataInfo: UserModel;
  getMypositionSubscription: Subscription;
  salesmanStatus: number;
  OffersCheck: Observable<any>;
  salesReqEnd: boolean = false;
  UserTypes = UserEnum
  // zoomLinkSalesMan: string;
  // zoomCustomer: { zoomUrl: string; zoomId: string; zoomPassword: string; appointmentId: number };
  // zoomStatus: string = "noInQueue";
  // zoomDeveloperName: string = "";
  // zoomDeveloperId: string = "";
  // zoomUnitId: string = "";
  // isMeetingZoomType: boolean = false;
  // audio = new Audio();
  // getMyPositionCallTimes: number = 0;
  // audioTimeOut: any;
  // audioInterval: any;
  // isInCall: any;
  // showZoomPopup: boolean = false;
  // zoomPopupOpened: boolean = false;
  // userQuitZoom: boolean = false;
  showBar = true;
  // hasSuvey: boolean = false;
  lang;
  //#endregion

  //#region constructor

  constructor(
    private injector: Injector,
    public router: Router,
    private userService: UserDAOService,
    // private socketService: SocketService,
    private OffersService: OffersService,
    private _dataLayerService: DataLayerService,
    // private salesmanService: SalesmanService,
    public hjService: NgxHotjarService,
    private dialog: MatDialog,
    // private zoomService: ZoomService,
    private pullBackService: PullBackService,
    private toasterService: HotToastService,
    private generalService: GeneralService,
    private ReservationTriggerService: ReservationTriggerService
  ) {
    this.lang = this.generalService.getCurrentLang();
  }
  //#endregion

  ngOnInit(): void {
    // setTimeout(() => {
    //   // this.loadChatbot();
    //   // this.loadZoomComponent();
    // }, 12000);

    // this.audio.src = "../../../../assets/simple_notification.mp3";
    this.OffersCheck = this.OffersService.getOffers();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        //if our event is of our interest
        this._dataLayerService.logPageView(event.url); //call our dataLayer service's page view method to ping home with the url value.
        this.content?.scrollTo({
          top: 0,
        });
        this.sideBar?.close();
        this.activeURL = event.urlAfterRedirects.split("?")[0].slice(3); // Splits url. Removes queryparams and lang prefix
      }
    });

    // Get User data from request to update the 'userdata' variable which is used to check if the user is logged in and triggers some DOM events
    // like switching header buttons from 'login/register' state to 'My account' state

    // this.zoomService.contactSalesOpened.subscribe((opened) => {
    //   localStorage.setItem("contactOpened", opened == true ? "true" : "false");
    //   this.zoomPopupOpened = opened;
    //   if (!this.zoomPopupOpened) {

    //     this.userService.userBasic$.subscribe((res) => {
    //       if (res == null) {
    //         this.getMypositionSubscription?.unsubscribe();
    //         this.getMyPositionReq();
    //       }
    //       if (res) {
    //         if (res.userType !== 2) {
    //           this.getMypositionSubscription?.unsubscribe();
    //           this.getMyPositionReq();
    //         } else {
    //           this.getMypositionSubscription?.unsubscribe();
    //         }
    //       }
    //     });
    //   }
    //   if (this.zoomPopupOpened) {
    //     this.stopAudio();
    //     this.isMeetingZoomType = false;
    //     this.getMypositionSubscription.unsubscribe();
    //   } else if (!this.zoomPopupOpened && this.zoomStatus !== "noInQueue") {
    //     this.isMeetingZoomType = true;
    //     if (this.zoomStatus == "onCall") {
    //       this.showNotification();
    //     }
    //   }
    // });
    // window.addEventListener("storage", () => {
    //   let popupIsOpened = JSON.parse(window.localStorage.getItem("contactOpened"));
    //   if (popupIsOpened == true) {
    //     this.stopAudio();
    //     this.isMeetingZoomType = false;
    //     this.getMypositionSubscription.unsubscribe();
    //   } else if (popupIsOpened == false) {
    //     this.userService.userBasic$.subscribe((res) => {
    //       if (res == null) {
    //         this.getMyPositionReq();
    //       }
    //       if (res) {
    //         if (res.userType !== 2) {
    //           this.getMyPositionReq();
    //         } else {
    //           this.getMypositionSubscription.unsubscribe();
    //         }
    //       }
    //     });
    //   }
    // });
    this.userService.userBasic$.subscribe((res: any) => {
      this.userDataInfo = res;
      if (this.userDataInfo?.userType == 2) {
        this.loadSalesManSection();

      }
    });

    // this.getSalesDetails();
    // this.socketService.serverWork$.subscribe((server) => {
    //   if (server) {
    //     this.getSalesDetails();
    //   }
    // });
    // this.socketService.status$.subscribe((status) => {
    //   this.salesmanStatus = status;
    //   this.incomingCallComponentCheck();
    // });
    this.ReservationTriggerService.getReservation().subscribe((res) => {
      if (res?.flag) this.reservationPullBack(res.compName, res.reId);
    });
  }
  // loadChatbot() {
  //   import("../components/chatbot/chatbot.module").then((m) => {
  //     const moduleRef = createNgModule(m.ChatbotModule, this.injector);
  //     const components = moduleRef.instance.getComponents();
  //     this.chatbotContainer.createComponent(components.ChatbotCloneComponent, { ngModuleRef: moduleRef });
  //   });
  // }
  // loadZoomComponent() {
  //   import("src/app/sales-man/shared-component/shared-component.module").then((m) => {
  //     const moduleRef = createNgModule(m.SharedComponentModule, this.injector);
  //     const components = moduleRef.instance.getComponents();
  //     this.zoomContainer.createComponent(components.ZoomContainerComponent, { ngModuleRef: moduleRef });
  //   });
  // }
  loadSalesManSection() {
    import("src/app/sales-man/shared-component/shared-component.module").then((m) => {
      const moduleRef = createNgModule(m.SharedComponentModule, this.injector);
      const components = moduleRef.instance.getComponents();

      // const callComponent = this.salesManContainer.createComponent(components.CallComponent, { ngModuleRef: moduleRef });
      // callComponent.setInput("zoomLink", this.zoomLinkSalesMan);
      // callComponent.setInput("salesStatus", this.salesmanStatus);
      // callComponent.instance.salesReqEnd.subscribe((value) => {
      //   this.salesReqinCall(value);
      // });
      this.salesManContainer.createComponent(components.ApointmentsComponent, { ngModuleRef: moduleRef });
    });
  }
  // incomingCallComponentCheck() {
  //   if (this.salesmanStatus == 4 && this.salesReqEnd == true) {
  //     if (!this.incomingCallContainer.length)
  //       import("src/app/sales-man/shared-component/shared-component.module").then((m) => {
  //         const moduleRef = createNgModule(m.SharedComponentModule, this.injector);
  //         const components = moduleRef.instance.getComponents();
  //         const incomingCallComponent = this.incomingCallContainer.createComponent(components.IncomingCallComponent, {
  //           ngModuleRef: moduleRef,
  //         });
  //         incomingCallComponent.instance.zoomUrl.subscribe((value) => {
  //           this.zoomUrl(value);
  //         });
  //       });
  //   } else {
  //     this.incomingCallContainer?.clear();
  //   }
  // }
  // showNotification() {
  //   this.showZoomPopup = true;
  //   this.audio.load();
  //   this.audio.play();
  //   this.audio.loop = true;
  //   setTimeout(() => {
  //     this.showZoomPopup = false;
  //     this.audio.pause();
  //     this.audio.currentTime = 0;
  //   }, 5000);
  // }

  // salesReqinCall(req: boolean) {
  //   this.salesReqEnd = req;
  //   this.incomingCallComponentCheck();
  // }
  // zoomUrl(url: string) {
  //   this.zoomLinkSalesMan = url;
  // }
  closeSideBar(): void {
    this.sideBar.close();
  }
  ngAfterViewInit(): void { }
  loginSignupTrigger() {
    this.sideBar.close();
  }

  // getSalesDetails() {
  //   if (this.userDataInfo?.userType == 2) {
  //     this.salesmanService.getSalesmanStatus().subscribe((res: SalesManStatusModel) => {
  //       this.socketService.salesmanStatus(res.status);
  //       this.salesmanStatus = res.status;
  //       this.incomingCallComponentCheck();
  //       this.socketService.callDetails$.next({
  //         callName: res.message,
  //         appointmentId: res.appointId,
  //         appoitmentMethod: res.appointmentMethod,
  //         appoitnmentType: res.appointmentTypeId,
  //         callNumber: res.mobile,
  //         userId: res.usrId,
  //         timerInMinutes: res.timerInMinutes,
  //         whatsappNumber: res.whatsappNumber,
  //         startUrl: res.startUrl,
  //         compId: res.compId,
  //         unitId: res.unitId,
  //         devId: res.devId,
  //       });
  //       this.socketService.callDetails$.subscribe((call) => { });
  //     });
  //   }
  // }
  // openContactSalesman() {
  //   if (this.zoomStatus == "onCall" && this.zoomCustomer?.zoomId != null && this.zoomCustomer?.zoomUrl != null) {
  //     let name = "Guest";
  //     let zoomLink = `${environment.zoomUrl}${this.zoomCustomer.zoomId}/${this.zoomCustomer.zoomPassword}/${name}/${0}`;
  //     this.salesmanService.userInteracted(this.zoomCustomer.appointmentId).subscribe(() => { });
  //     let obj = {
  //       url: zoomLink,
  //       dev: this.zoomDeveloperName,
  //     };
  //     this.zoomService.setZoomUrl(obj);
  //     this.zoomService.contactSalesOpened.next(true);
  //     this._dataLayerService.logEvent("zoom_conversion", "Buttons", "Fetched from Data Layer", "ContactSalesmanViaZoomConversionButton");
  //   } else {
  //     this.lazyLoadContactSalesmanDialog();
  //     // this.dialog.open(ContactSalesmanDialogComponent, {
  //     //   panelClass: "contact-salesman-dialog-container",
  //     //   data:
  //     // });
  //   }
  //   this.stopAudio();
  // }
  // stopAudio() {
  //   this.audio.pause();
  //   this.audio.currentTime = 0;
  //   clearInterval(this.audioInterval);
  //   clearTimeout(this.audioTimeOut);
  //   this.isInCall?.unsubscribe();
  // }

  getMyPositionReq() {
    // this.getMypositionSubscription = timer(0, 10000)
    //   .pipe(switchMap(() => this.salesmanService.getMyPosition({ devId: "", appointType: 1 })))
    //   .subscribe((res) => {
    //     this.zoomDeveloperName = res.developerName;
    //     this.zoomDeveloperId = res.developerId;
    //     this.zoomUnitId = res.unitId;
    //     this.userQuitZoom = res.userQuitZoom;
    //     if (this.userQuitZoom) {
    //       this.stopAudio();
    //       return;
    //     }
    //     if (!this.zoomPopupOpened) {
    //       let popupIsOpened = JSON.parse(window.localStorage.getItem("contactOpened"));
    //       this.isMeetingZoomType = res.appointmentType == 1 && !popupIsOpened ? true : false;
    //     } else {
    //       this.isMeetingZoomType = false;
    //     }
    //     switch (res.operation) {
    //       case 1: {
    //         this.zoomStatus = "noInQueue";
    //         this.zoomService.setZoomUrl(null);
    //         this.getMyPositionCallTimes = 0;
    //         this.stopAudio();
    //         this.isInCall?.unsubscribe();
    //         break;
    //       }
    //       case 2: {
    //         this.zoomStatus = "inQueue";
    //         this.zoomService.setZoomUrl(null);
    //         this.getMyPositionCallTimes = 0;
    //         this.isInCall?.unsubscribe();
    //         this.stopAudio();

    //         break;
    //       }
    //       case 3: {
    //         this.zoomStatus = "inProgress";
    //         this.stopAudio();
    //         this.isInCall?.unsubscribe();
    //         break;
    //       }
    //       case 4: {
    //         this.zoomStatus = "onCall";
    //         this.zoomCustomer = {
    //           zoomUrl: res.zoomUrl,
    //           appointmentId: res.appointmentId,
    //           zoomId: res.zoomId,
    //           zoomPassword: res.zoomPassword,
    //         };
    //         this.isInCall = interval(30000)
    //           .pipe(
    //             take(1),
    //             map((val) => {
    //               this.zoomService.getZoomUrl().subscribe((url) => {
    //                 if (!url && !this.hasSuvey) {
    //                   this.pullBackSurvey(res.developerName);
    //                 }
    //                 this.isInCall?.unsubscribe();
    //               });
    //             })
    //           )
    //           .subscribe();
    //         this.getMyPositionCallTimes++;
    //         if (this.getMyPositionCallTimes < 2) {
    //           this.audioInterval = setInterval(() => {
    //             this.showNotification();
    //           }, 30000);
    //         }
    //         break;
    //       }
    //       case 5: {
    //         this.zoomStatus = "alreadyScheduled";
    //         this.stopAudio();
    //         this.isInCall?.unsubscribe();
    //         break;
    //       }
    //       case 6: {
    //         this.zoomStatus = "noAvailableSalesMan";
    //         this.zoomService.setZoomUrl(null);
    //         this.isInCall?.unsubscribe();
    //         this.stopAudio();

    //         break;
    //       }
    //       default: {
    //         this.zoomStatus = "noInQueue";
    //         this.stopAudio();
    //         this.zoomService.setZoomUrl(null);
    //         this.isInCall?.unsubscribe();
    //         this.getMyPositionCallTimes = 0;
    //         break;
    //       }
    //     }
    //   });
  }

  // lazyLoadContactSalesmanDialog() {
  //   const dialogData: LazyLoadingPopupData = {
  //     import: import("src/app/sales-man/contact-salesman-dialog/contact-salesman-dialog.component"),
  //     component: "ContactSalesmanDialogComponent",
  //     data: {
  //       unitId: this.zoomUnitId,
  //       developerId: this.zoomDeveloperId,
  //       developerName: this.zoomDeveloperName,
  //       tab: "zoom",
  //       servay: true,
  //       schedule: true,
  //     },
  //   };
  //   this.dialog.open(LazyLoadingPopupComponent, { data: dialogData, panelClass: "contact-salesman-dialog-container" });
  // }
  // pullBackSurvey(devName) {
  //   this.hasSuvey = true;
  //   this.isInCall?.unsubscribe();
  //   this.pullBackService.getSurveys().subscribe((res: any) => {
  //     let options = res
  //       .filter((o) => o.surveyType == 1)
  //       .map((option) => {
  //         return {
  //           text: option.surveyReasonText,
  //           id: option.surveyReasonId,
  //         };
  //       });
  //     const dialogData: LazyLoadingPopupData = {
  //       import: import("src/app/shared/components/pull-back-popup/pull-back-popup.component"),
  //       component: "PullBackPopupComponent",
  //       data: {
  //         question:
  //           this.lang != "en"
  //             ? `لماذا لم تكمل اجتماعك مع مندوب ${devName}؟`
  //             : `How the meeting with <strong>${devName}</strong> Representative fell through?`,

  //         typeId: 1,
  //         optons: [...options],
  //       },
  //     };
  //     this.dialog
  //       .open(LazyLoadingPopupComponent, { data: dialogData })
  //       .afterClosed()
  //       .subscribe((id) => {
  //         if (id) {
  //           this.pullBackService.setSurvey({ surveyTypeId: 1, surveyReasonId: id }).subscribe();
  //           if (id == 5) {
  //             this.openContactSalesman();
  //           } else {
  //             if (id == 4) {
  //               this.lazyLoadContactSalesmanDialog();
  //               // this.dialog.open(ContactSalesmanDialogComponent, {
  //               //   panelClass: "contact-salesman-dialog-container",
  //               //   data: {
  //               //     unitId: this.zoomUnitId,
  //               //     developerId: this.zoomDeveloperId,
  //               //     developerName: this.zoomDeveloperName,
  //               //     tab: "zoom",
  //               //     servay: true,
  //               //     schedule: true,
  //               //   },
  //               // });
  //             }
  //             this.toasterService.success(this.lang != "en" ? "شكراً على تعاونكم معنا" : "Thank you for your feedback.");
  //             this.zoomService.contactSalesOpened.next(true);
  //           }
  //         }
  //       });
  //   });
  // }

  reservationPullBack(cmpname, regId) {
    this.pullBackService.getSurveys().subscribe((res: any) => {
      let options = res
        .filter((o) => o.surveyType == 2)
        .map((option) => {
          return {
            text: option.surveyReasonText,
            id: option.surveyReasonId,
          };
        });
      const dialogData: LazyLoadingPopupData = {
        import: import("src/app/features/pull-back-popup/pull-back-popup.component"),
        component: "PullBackPopupComponent",
        data: {
          question:
            this.lang != "en" ? `لماذا لم تقوم بحجز وحدتك في '${cmpname}'؟` : `Why didn't you complete the "${cmpname}" reservation`,
          typeId: 1,
          optons: [...options],
        },
      };
      this.dialog
        .open(LazyLoadingPopupComponent, { data: dialogData })
        .afterClosed()
        .subscribe((id) => {
          if (id) {
            this.pullBackService.setSurvey({ surveyTypeId: 2, surveyReasonId: id }).subscribe();
            if (id == 9) {
              // this.zoomDeveloperName = this.lang != "en" ? "اشتري" : "Eshtri";
              // this.openContactSalesman();
            } else if (id == 10) {
              this.router.navigateByUrl(`${this.lang}/reservation/${regId}`);
            }
            this.toasterService.success(this.lang != "en" ? "شكراً على تعاونكم معنا" : "Thank you for your feedback.");
          }
        });
    });
  }
  closeAction() {
    this.showBar = false;
  }

  openRequestPopup() {
    const dialogData: LazyLoadingPopupData = {
      import: import("../components/special-request/special-request.component"),
      component: "SpecialRequestComponent",
    };
    this.dialog.open(LazyLoadingPopupComponent, { data: dialogData, panelClass: "special-request" });
  }
}

//
