<app-instruction-bar
  *ngIf="showBar && userDataInfo?.userType != 2 && activeURL != '/listings' && !activeURL.includes('/buy/')"
  (closeAction)="closeAction()"></app-instruction-bar>
<mat-sidenav-container class="example-sidenav-container">
  <mat-sidenav-content id="scroll" adjustScroll #content>
    <app-sub-header></app-sub-header>
    <app-header (menuToggle)="sideBar.toggle()"></app-header>
    <main class="main-content">
      <router-outlet #o="outlet"></router-outlet>
      <app-feedback *ngIf="userDataInfo?.userType != 2"></app-feedback>
      <!--  <app-zoom-container></app-zoom-container> -->
      <!-- <app-chatbot-clone></app-chatbot-clone> -->
      <ng-container #zoomContainer></ng-container>
      <!-- <ng-container #chatbotContainer></ng-container> -->
    </main>
    <app-footer></app-footer>
  </mat-sidenav-content>

  <mat-sidenav #sideBar fixedInViewport="true" mode="over">
    <nav class="nav__container">
      <div class="nav__close" (click)="closeSideBar()">
        <mat-icon>close</mat-icon>
      </div>
      <!-- NAVIGATION SECTION -->
      <ul class="nav__elements">
        <li class="nav__element">
          <a class="nav__item" routerLink="{{'/listings' | localize}}" routerLinkActive="active">{{ 'navbar.search' |
            translate }}</a>
        </li>
        <li class="nav__element " style="display: flex; align-items: center;">
          <mat-icon svgIcon="resell"></mat-icon>
          <a class="nav__item" routerLink="{{'/buy/resell' | localize}}" routerLinkActive="active">{{ 'navbar.resell' |
            translate }}</a>
        </li>
        <li class="nav__element">
          <a class="nav__item" routerLink="{{'/projects' | localize}}" routerLinkActive="active">{{ 'navbar.projects' |
            translate }}</a>
        </li>
        <li class="nav__element">
          <a class="nav__item" routerLink="{{'/developers' | localize}}" routerLinkActive="active">{{
            'navbar.developers' |
            translate }}</a>
        </li>
        <li class="nav__element">
          <a class="nav__item" routerLink="{{'/locations' | localize}}" routerLinkActive="active">{{ 'navbar.locations'
            |
            translate }}</a>
        </li>
        <li *ngIf="userDataInfo?.userType == 2" class="nav__element">
          <a class="nav__item" routerLink="{{'/customer-service/customers/1' | localize}}" routerLinkActive="active">{{
            'cs.customers' |
            translate }}</a>
        </li>
        <ng-container *rxLet="OffersCheck ;let offers ;patchZone:false; strategy:'low'">
          <li *ngIf="offers.LandingProjects?.length > 0" class="nav__element">
            <a class="nav__item" routerLink="{{'/offers' | localize}}" routerLinkActive="active">{{ 'navbar.offers' |
              translate }}</a>
          </li>
        </ng-container>
        <li class="nav__element adib">
          <mat-icon svgIcon="calculator"></mat-icon>
          <a class="nav__item" routerLink="{{'/adib' | localize}}" routerLinkActive="active">{{ 'navbar.adib' |
            translate }}</a>
        </li>
        <li class="nav__element" *ngIf="userDataInfo?.userType == UserTypes.USER">
          <a class="nav__item" routerLink="{{'/addYourUnit/0' | localize}}" routerLinkActive="active">{{
            'navbar.addProperty'
            |
            translate }}</a>
        </li>
        <li class="nav__element request">
          <mat-icon svgIcon="chat"></mat-icon>
          <button class="nav__item" (click)="openRequestPopup()">{{ "navbar.request" | translate }}</button>
        </li>
        <li class="nav__element">
          <a class="nav__item" routerLink="{{'/about-us' | localize}}" routerLinkActive="active">{{ 'navbar.about' |
            translate }}</a>
        </li>

        <register-nav-item (afterloginTriggerClose)="loginSignupTrigger()"></register-nav-item>
        <li *ngIf="userDataInfo?.userType != 2" class="nav__element">
          <a class="nav__item" routerLink="{{'/contact-us' | localize}}" routerLinkActive="active">{{ 'navbar.contact' |
            translate }}</a>
        </li>

        <li class="nav__element country">
          <app-countries-dropdown></app-countries-dropdown>
        </li>
        <li class="nav__element">
          <app-flag-dropdown-list></app-flag-dropdown-list>
        </li>
      </ul>
      <!-- END OF NAVIGATION SECTION -->
    </nav>
  </mat-sidenav>

  <!-- </mat-sidenav-content> -->
</mat-sidenav-container>
<!-- </div> -->
<section class="salesman">
  <ng-container #incomingCallContainer></ng-container>
  <ng-container #salesManContainer></ng-container>
  <!-- <app-incoming-call (zoomUrl)="zoomUrl($event)" *ngIf="salesmanStatus == 4 && salesReqEnd == true"></app-incoming-call>
  <app-call (salesReqEnd)="salesReqinCall($event)" [salesStatus]="salesmanStatus"
    [zoomLink]="zoomLinkSalesMan"></app-call>
  <app-apointments></app-apointments> -->
</section>

<!-- <div class="zoom-container" [ngStyle]="{ left: lang == 'en' ? '3%' : 'unset', right: lang == 'ar' ? '3%' : 'unset' }"
  (click)="openContactSalesman()" *ngIf="
    zoomStatus !== 'noInQueue' &&
    (zoomStatus == 'inQueue' || zoomStatus == 'noAvailableSalesMan' || zoomStatus == 'alreadyScheduled' || zoomStatus == 'inProgress') &&
    isMeetingZoomType &&
    userDataInfo?.userType != 2 &&
    !userQuitZoom
  ">
  <img class="zoom-img" src="assets/svg/video-camera.svg" alt="zoom-logo" loading="lazy" />
</div>

<div class="zoom-available" [ngStyle]="{ left: lang == 'en' ? '3%' : 'unset', right: lang == 'ar' ? '3%' : 'unset' }"
  (click)="openContactSalesman()"
  *ngIf="zoomStatus !== 'noInQueue' && zoomStatus == 'onCall' && isMeetingZoomType && userDataInfo?.userType != 2 && !userQuitZoom">
  <div class="popup" *ngIf="showZoomPopup">
    <p class="msg">VPA ready, join now!</p>
  </div>
  <div class="img">
    <img class="zoom-img" src="assets/svg/video-camera-black.svg" alt="zoom-logo" loading="lazy" />
  </div>
</div> -->