import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class JwtInterceptor implements HttpInterceptor {
  urlsToNotUse: Array<string>;
  constructor() {
    //urls
    this.urlsToNotUse = [`${environment.staticUrl}/api/cms/home-page-blogs`, `${environment.staticUrl}/api/cms/developer-blogs-and-events`];
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const tokenLocal = localStorage.getItem("token");
    const token = tokenLocal ? tokenLocal?.substring(1, tokenLocal.length - 1) : " ";
    if (token !== " " && this.isValidRequestForInterceptor(req.url)) {
      // Don't set content type if uploading attachment
      if (req.url.includes("attachments/id") || req.url.includes("attachments")) {
        const modifiedReq = req.clone({
          headers: new HttpHeaders({
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("LOCALIZE_DEFAULT_LANGUAGE") ? localStorage.getItem("LOCALIZE_DEFAULT_LANGUAGE") : "en",
          }),
        });
        return next.handle(modifiedReq);
      } else {
        const modifiedReq = req.clone({
          headers: new HttpHeaders({
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Accept-Language": localStorage.getItem("LOCALIZE_DEFAULT_LANGUAGE") ? localStorage.getItem("LOCALIZE_DEFAULT_LANGUAGE") : "en",
          }),
        });
        return next.handle(modifiedReq);
      }
    } else {
      const modifiedReq = req.clone({
        headers: new HttpHeaders({
          Authorization: " ",
          "Content-Type": "application/json",
          "Accept-Language": localStorage.getItem("LOCALIZE_DEFAULT_LANGUAGE") ? localStorage.getItem("LOCALIZE_DEFAULT_LANGUAGE") : "en",
        }),
      });
      return next.handle(modifiedReq);
    }
  }
  private isValidRequestForInterceptor(requestUrl: string): boolean {
    let destination: string = requestUrl;
    for (let address of this.urlsToNotUse) {
      if (new RegExp(address).test(destination)) {
        return false;
      }
    }

    return true;
  }
}
