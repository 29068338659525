import { ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-lazy-loading-popup',
  templateUrl: './lazy-loading-popup.component.html',
  styleUrls: ['./lazy-loading-popup.component.scss'],
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule]
})
export class LazyLoadingPopupComponent implements OnInit {
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef
  loading: boolean = true
  constructor(@Inject(MAT_DIALOG_DATA) public data: LazyLoadingPopupData) { }

  ngOnInit(): void {
    this.lazyLoadComponent()
  }
  lazyLoadComponent() {
    this.data.import.then((m) => {
      const component = this.container.createComponent<any>(m[this.data.component])
      this.loading = false
      if (this.data.data && component.instance.hasOwnProperty('data')) {
        component.setInput('data', this.data.data)
      }
    })
  }

}
export interface LazyLoadingPopupData {
  import: Promise<any>;
  component: string;
  data?: any
}
